<template>
  <div>

    <create-early-enlistment></create-early-enlistment>

    <early-enlistment-table></early-enlistment-table>

  </div>
    
</template>

<script>
import {
  BButton
} from 'bootstrap-vue'
import {constants} from "@/helpers/constants"
import CreateEarlyEnlistment from './CreateEarlyEnlistment.vue'
import EarlyEnlistmentTable from './EarlyEnlistmentTable.vue'

export default {
  components: {
    BButton,
    CreateEarlyEnlistment,
    EarlyEnlistmentTable,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken')
    }
  },
  props: {
  },
  setup() {
  },
  created() {
  },
  methods: {
  
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
