var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Iniciar pistoleo temprano"}},[_c('validation-observer',{ref:"createEnlistmentValidation"},[_c('b-form',{staticClass:"auth-forgot-password-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.createEnlistment($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Tipo de Producto","label-for":"product_type_id"}},[_c('validation-provider',{attrs:{"name":"Tipo de Producto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"product_type_id","options":_vm.productTypeOptions},model:{value:(_vm.productTypeId),callback:function ($$v) {_vm.productTypeId=$$v},expression:"productTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Cajas por estiba","label-for":"box_by_stowage","description":"Cantidad de cajas que se pueden estibar"}},[_c('validation-provider',{attrs:{"name":"Cajas por estiba","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"box_by_stowage"},model:{value:(_vm.boxByStowage),callback:function ($$v) {_vm.boxByStowage=$$v},expression:"boxByStowage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Productos por caja","label-for":"products_by_box","description":"Cantidad de productos que van por caja"}},[_c('validation-provider',{attrs:{"name":"Productos por caja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"products_by_box"},model:{value:(_vm.productsByBox),callback:function ($$v) {_vm.productsByBox=$$v},expression:"productsByBox"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[(_vm.productTypeId != 1)?_c('b-form-group',{attrs:{"label":"Lleva SIM","label-for":"have_sim","description":"Selecciona si este alistamiento lleva o no SIM"}},[_c('validation-provider',{attrs:{"name":"Lleva SIM","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"have_sim","options":_vm.haveSimList},model:{value:(_vm.haveSim),callback:function ($$v) {_vm.haveSim=$$v},expression:"haveSim"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1035653076)})],1):_vm._e()],1)],1),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","label":"Loading..."}}),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit"}},[_vm._v(" Crear alistamiento ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }