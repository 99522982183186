var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Alistamientos"}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Buscar")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Buscar","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.stowages,"rtl":_vm.direction,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm },"select-options":{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"pagination-options":{
      enabled: true,
      perPage:_vm.pageLength
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'boxes_by_stowage')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.row.available_boxes_count)+" / "+_vm._s(props.row.boxes_by_stowage)+" ")]):(props.column.field === 'finalized')?_c('span',[_c('b-badge',{attrs:{"pill":"","variant":props.row.finalized == 1 ? 'light-success' : 'light-secondary'}},[_vm._v(" "+_vm._s(props.row.finalized == 1 ? 'Finalizada' : 'Pendiente')+" ")])],1):(props.column.field === 'available')?_c('span',[_vm._v(" "+_vm._s(props.row.available == 1 ? 'Disponible' : 'No Disponible')+" ")]):(props.column.field === 'additionals')?_c('span',[_c('b-badge',{attrs:{"pill":"","variant":props.row.have_sim ? 'info' : 'warning'}},[_vm._v(" "+_vm._s(props.row.have_sim ? 'Con SIM' : 'Sin SIM')+" ")])],1):(props.column.field === 'created_at')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateVariant(props.row.created_at))+" ")]):(props.column.field === 'updated_at')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateVariant(props.row.updated_at))+" ")]):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(props.row.finalized == 0)?_c('b-dropdown-item',{attrs:{"to":{ name: 'read-product-early-enlistment', params: { stowage_id: props.row.id }}}},[_c('feather-icon',{staticClass:"mr-50 text-success",attrs:{"icon":"ArrowRightIcon"}}),_c('span',[_vm._v("Continuar")])],1):_vm._e(),_c('b-dropdown-item',{attrs:{"to":{ name: 'stowage-detail', params: { stowage_id: props.row.id }}}},[_c('feather-icon',{staticClass:"mr-50 text-info",attrs:{"icon":"ListIcon"}}),_c('span',[_vm._v("Detalle")])],1),([1].includes(_vm.userData.user_profile_id))?_c('b-dropdown-item',{attrs:{"to":{
                name: 'traceability', 
                params: { 
                  loggable_type: 'estiba',
                  loggable_id: props.row.id,
                }
              }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ActivityIcon"}}),_c('span',[_vm._v("Trazabilidad")])],1):_vm._e()],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Mostrando 1 a ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['20','40','60']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" de "+_vm._s(props.total)+" items ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }