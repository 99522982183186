<template>
   <b-card title="Iniciar pistoleo temprano">

    <validation-observer ref="createEnlistmentValidation">

      <b-form
        class="auth-forgot-password-form mt-2"
        @submit.prevent="createEnlistment"
      >
        <b-row>

          <b-col
            cols="12"
            md="3"
          >
            
            <b-form-group
              label="Tipo de Producto"
              label-for="product_type_id"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Tipo de Producto"
                  rules="required"
                >

                <b-form-select
                  id="product_type_id"
                  v-model="productTypeId"
                  :options="productTypeOptions"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              
            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Cajas por estiba"
              label-for="box_by_stowage"
              description="Cantidad de cajas que se pueden estibar"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Cajas por estiba"
                  rules="required"
                >

                <b-form-input
                  type="number"
                  id="box_by_stowage"
                  v-model="boxByStowage"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Productos por caja"
              label-for="products_by_box"
              description="Cantidad de productos que van por caja"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Productos por caja"
                  rules="required"
                >

                <b-form-input
                  type="number"
                  id="products_by_box"
                  v-model="productsByBox"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            
            <b-form-group
              v-if="productTypeId != 1"
              label="Lleva SIM"
              label-for="have_sim"
              description="Selecciona si este alistamiento lleva o no SIM"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Lleva SIM"
                  rules="required"
                >

                <b-form-select
                  id="have_sim"
                  v-model="haveSim"
                  :options="haveSimList"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              
            </b-form-group>

          </b-col>


        </b-row>

        <!-- Action Buttons -->
        <b-spinner
          v-show="loading"
          variant="primary"
          label="Loading..."
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        />
        <b-button
          v-show="!loading"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Crear alistamiento
        </b-button>

      </b-form>
    </validation-observer>
    
  </b-card>


    
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormSelect, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from "@/helpers/constants"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      boxByStowage: null,
      productsByBox: null,
      productTypeId: null,
      required,
      loading: false,
      haveSim: true,
    }
  },
  props: {
  },
  setup(props) {
    const productTypeOptions = [
        {
          text: 'Selecciona una opción',
          value: null,
          disabled: true,
        },
        {
          text: 'BOLD NEO',
          value: '1',
        },
        {
          text: 'BOLD PLUS',
          value: '2',
        },
        {
          text: 'BOLD SMART',
          value: '3',
        },
        {
          text: 'BOLD PRO',
          value: '4',
        },
      ]

      const haveSimList = [
      {
          text: 'Con SIM',
          value: true,
        },
        {
          text: 'Sin SIM',
          value: false,
        },
      ]

    return {
      productTypeOptions,
      haveSimList
    }
  },
  created() {
  },
  methods: {
    createEnlistment() {
      event.preventDefault()
      this.$refs.createEnlistmentValidation.validate().then(success => {
        if (success) {

          this.loading = true

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/create-enlistment',{
            product_type_id: this.productTypeId,
            products_by_box: this.productsByBox,
            boxes_by_stowage: this.boxByStowage,
            have_sim: this.productTypeId != 1 ? this.haveSim : false,
          }, { headers }).then(response => {
            console.log('create-enlistment: ', response)
            this.loading = false

            if(response.data.status) {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `OK`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })

              let stowage = response.data.data.stowage
              this.$router.push({ name: 'read-product-early-enlistment', params: { stowage_id:stowage.id } })

            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Advertencia`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch( err => {
            this.loading = false

            console.log(err)
          })

        }
      })


    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
